import $ from "jquery";

class Database {
    constructor() {
        this._localhost = ""
        this.livetappeUrl = "https://livetappe.pro022.com"
        // this.livetappeUrl = "http://livetiming.local"
        this.livetimingUrl = "https://livetiming.pro022.com"
    }

    async getRaceKind(callback = () => {}) {
        let raceType = ""
        await $.ajax({
            type: "POST",
            url: this.livetappeUrl + "/get_race_type",
            success: (r) => {
                raceType = r.value
                callback(r)
            }
        })
        return raceType
    }

    async getDrivers(callback = () => {}) {
        return $.ajax({
            type: "POST",
            url: this.livetappeUrl + "/get_drivers",
            success: (r) => callback(r)
        })
    }

    async getData(callback = () => {}) {
        return $.ajax({
            url: this.livetimingUrl +  "/api/getData",
            type: "GET",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            success: (response) => {
              callback(response)
            },
          })
    }

    async getStatus(callback = () => {}) {
        return $.ajax({
            url: this.livetappeUrl +  "/get_status",
            type: "GET",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            success: (response) => {
              callback(response)
            },
          })
    }

    async getTrials(callback = () => {}) {
      return $.ajax({
        url: this.livetappeUrl +  "/get_trials",
        type: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        success: (response) => {
          callback(response)
        },
      })
    }
}

export default Database