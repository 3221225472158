
import React, {useEffect} from "react";
import { Container, Row, Col } from "react-bootstrap";
import RaceController from "../../controllers/race_controller";
import FlipMove from "react-flip-move";

// ------------------- MOBILE LAYOUT ---------------------------

function PilotsList(props) {
  let setSelectedDriver = props.setSelectedDriver
  let raceController = props.raceController;
  let raceKind = props.raceKind

  useEffect(() => {
    // raceController type check
    if(!(raceController instanceof RaceController)) console.warn("PilotsList doesn't riceved a RacController: ", typeof raceController)
  }, [raceController])
  
    return (
      <div>
        <div className="red-cell mb-4">
          <h4 className="mb-0">Classifica</h4>
        </div>
        {raceKind == "crono" ?
        <CronoList setSelectedDriver={setSelectedDriver} raceController={raceController}/> : 
        <MixedList setSelectedDriver={setSelectedDriver} raceController={raceController}/>
        }
        <br />
      </div>
    );
  }

  function CronoList(props) {
    let raceController = props.raceController
    let setSelectedDriver = props.setSelectedDriver

    useEffect(() => {
      // raceController type check
      if(!(raceController instanceof RaceController)) console.warn("CronoList doesn't riceved a RacController: ", typeof raceController)
    }, [raceController])

    return (
      <>
      <FlipMove>
        {raceController.getDriverList().map((driver, index) => (
          <div key={driver.getName() + driver.getSurname()} className="white-cell mt-2" onClick={() => setSelectedDriver(driver)}>
            <Row>
              <Col xs="2" className="position-container pr-0">
                <h6 className="position">{driver.getPosition()}</h6>
              </Col>
              <Col xs="10" className="pl-0">
                <h5 className="text-left name">
                  {driver.getName()}{" "}
                  <span className="thin font-small">
                    {driver.getTeam()}
                  </span>
                </h5>
                <hr />
                <Row className="font-small">
                  <Col className="pr-0">
                    <h6>
                      <span className="thin">T1:</span>
                      {
                        driver.getT1() == ""
                          ? " --:--:--"
                          : driver.getT1()
                      }
                    </h6>
                  </Col>
                  <Col className="pl-0 pr-0">
                    <h6>
                      <span className="thin">T2:</span>
                      {
                        driver.getT2() == ""
                          ? " --:--:--"
                          : driver.getT2()}
                    </h6>
                  </Col>
                  <Col className="pl-0">
                    <h6>
                      <span className="thin">T3:</span>
                      {
                        driver.getT3() == ""
                          ? " --:--:--"
                          : driver.getT3()
                      }
                    </h6>
                  </Col>
                </Row>
                <hr />
                <Row className="font-small text-center">
                  <Col className="pr-0 text-left">
                    <h6>
                      <span className="thin">DIF:</span>
                      {
                        driver.getDif() == ""
                          ? " --:--:--"
                          : driver.getDif()
                      }
                    </h6>
                    <h6>
                      <span className="thin">Gap 1°:</span>
                      {
                        driver.getGapToFirst() == ""
                          ? " --:--:--"
                          : driver.getGapToFirst()
                      }
                    </h6>
                  </Col>
                  <Col className="pl-0 text-right">
                    <h6
                      className={
                        driver.getBestLap() == raceController.getBestTime()
                          ? "red"
                          : ""
                      }
                    >
                      <span className="thin">Best lap:</span>
                      {driver.getBestLap() == "" ? " --:--:--" : driver.getBestLap()}
                    </h6>
                    <h6>
                      <span className="thin">Last lap:</span>
                      {driver.getLastLap() == "" ? " --:--:--" : driver.getLastLap()}
                    </h6>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        ))}
      </FlipMove>
      </>
    )
  }

  function MixedList(props) {
    let raceController = props.raceController
    let setSelectedDriver = props.setSelectedDriver

    useEffect(() => {
      // raceController type check
      if(!(raceController instanceof RaceController)) console.warn("PilotsList doesn't riceved a RacController: ", typeof raceController)
    }, [raceController])

    return (
      <>
        <FlipMove>
          {raceController.getDriverList().map((driver, index) => (
            <div key={driver.getDriverCode()} className="white-cell mt-2" onClick={() => setSelectedDriver(driver)}>
              <h5 className="mt-2"><span>{index + 1}</span> - {driver.getSurname()} {driver.getName()}</h5>
              <span className="">Somma: {driver.getSum()}</span>
              <hr className="mb-2"/>
              <Row className="mb-1">
                {
                  Object.keys(raceController.getTrials()).slice(0,3).map((trial) =>
                    <Col>
                      <h6>{raceController.getTrials()[trial]}</h6>
                      <p style={{fontSize: "13px"}}>{driver[trial]}</p>
                    </Col>
                  )
                }
              </Row>
              {
                Object.keys(raceController.getTrials()).length > 3
                ? <div className="mb-1">
                    <b>...</b>
                  </div>
                : <></>
              }
            </div>
          ))}
        </FlipMove>
      </>
    )
  }

  export default PilotsList