import LiveTiming from "./pages/livetiming";
import {useEffect} from "react"

//---------------- IMPORT STYLE ----------------

import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
import FlipMove from "react-flip-move";
import Maintenance from "./pages/maintenance";
import Database from "./components/database";

//---------------- MAIN FUNCTION ----------------
// function App() {
//   return <LiveTiming />
// }
function App() {
  const [status, setStatus] = useState("on")

  useEffect(() => {
    let db = new Database()
    db.getStatus((status) => setStatus(status[0]['value']))
  }, [])

  return (
    <>
      {
        status != "off" ? 
          <LiveTiming /> :
          <Maintenance />
      }
    </>
  )
}

export default App;
