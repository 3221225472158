class Driver {
  constructor() {
    // crono race info
    this.position = -1
    this.name = ""
    this.team = ""
    this.t1 = "--:--:---"
    this.t2 = "--:--:---"
    this.t3 = "--:--:---"
    this.last_lap = ""
    this.best_lap = ""
    this.laps = ""
    this.gap_to_first = ""
    this.dif = ""
    this.regNumber = ""

    // point race info
    this.driver_code = ""
    this.id_mask = ""
    this.surname = ""
    this.sum = ""
    // this.name = "" defined above
    // this.t1 = ""
    // this.t2 = ""
    // this.t3 = ""
  }
}

export default Driver