import React, { useState, useEffect } from "react";
import RaceController from "../../controllers/race_controller";
import Table from "../../components/table"
import PilotsList from "../../components/driverList";
import { Container, Row, Col } from "react-bootstrap";
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import "./style.css";

//---------------- DEFAULT VALUE ---------------

const mobileBreakpoint = 768;

//---------------- MAIN FUNCTION -----------------

function LiveTiming(props) {
  const [raceController, setRaceController] = useState(new RaceController())
  const [onMobile, setOnMobile] = useState(false);

  // specific driver info(viwed inside the side/bottom panel)
  const [selectedDriver, setSelectedDriver] = useState(undefined)

  // help-function for the next useEffect
  function onResize() {
    if (window.innerWidth <= mobileBreakpoint) {
      if (!onMobile) {
        setOnMobile(true);
      }
    } else {
      if (onMobile) {
        setOnMobile(false);
      }
    }
  }

  // managing mobile/desktop layout
  useEffect(() => {
    window.addEventListener("resize", onResize);
    onResize();

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [onMobile]);

  // managing point race title
  useEffect(() => {
    if(raceController.getRaceType() != "crono")
      raceController.setRaceName("Gara a tappe")
  }, [raceController.getRaceType()])

  // loading race controller
  useEffect(() => {
    raceController.setState(setRaceController)
    raceController.loadInfo()
  }, [])


  // used to update the info of the driver on the panel when it is open
  useEffect(() => {
    if(selectedDriver) {
      let idMask = selectedDriver.getIdMask()
      let newSelectedDriver = raceController.getDriverList().filter((driver) => driver.getIdMask() == idMask)
      if(newSelectedDriver.length == 1) setSelectedDriver(newSelectedDriver[0])
      else setSelectedDriver(undefined)
    }
  }, [raceController.getDriverList()])

  return (
    <>
      <div className="text-center">
        {/* LOGO */}
        {onMobile ? (
          <img
            className="img-fluid"
            src="https://drive.google.com/uc?export=view&id=1yYS8u2uDLZ2lsK2toN9JP8Za5-r01zPo"
            alt="Loghi"
          />
        ) : (
          <img
            className="img-fluid"
            src="https://drive.google.com/uc?export=view&id=1FLPTAHmGQbo0U2oV_F1fv8sozDrFNAvk"
            alt="Loghi"
          />
        )}
      </div>
      {/* MANAGE CONTENT LAYOUT */}
      <Container>
        <br />
        <br />
        <Infos raceController={raceController}/>
        {
          onMobile ?
            <PilotsList
              setSelectedDriver={setSelectedDriver}
              raceController={raceController}
              raceKind={raceController.getRaceType()}/> :
            <Table
              setSelectedDriver={setSelectedDriver}
              raceController={raceController}
              raceKind={raceController.getRaceType()}/>
        }
      </Container>
      <SwipeableDrawer
        anchor={onMobile ? "bottom" : "right"}
        open={selectedDriver != undefined}
        onClose={() => setSelectedDriver(undefined)}
        onOpen={() => {}}
      >
        <div id="panel">
          <div className="">
          <h1 className="position mx-auto">{selectedDriver && selectedDriver.getPosition()}</h1>
          </div>
          <h3 className="name text-center">
            {selectedDriver && selectedDriver.getName() + " " + selectedDriver.getSurname()}
          </h3>
          <p>{selectedDriver && selectedDriver.getTeam()}</p>
          <br/>
          {
            raceController.getRaceType() == "crono"
            ? <Row>
                <Col>
                  <p className="thin">T1</p>
                  <b>{selectedDriver && (selectedDriver.getT1() == "" ? "--:--:---" : selectedDriver.getT1())}</b>
                </Col>
                <Col>
                  <p className="thin">T2</p>
                  <b>{selectedDriver && (selectedDriver.getT2() == "" ? "--:--:---" : selectedDriver.getT2())}</b>
                </Col>
                <Col>
                  <p className="thin">T3</p>
                  <b>{selectedDriver && (selectedDriver.getT3() == "" ? "--:--:---" : selectedDriver.getT3())}</b>
                </Col>
              </Row>
            : <Row className="mb-1">
                {
                  Object.keys(raceController.getTrials()).map((trial) =>
                    <Col className="mt-1">
                      <h6 className="m-auto">{raceController.getTrials()[trial]}</h6>
                      <p style={{fontSize: "13px"}}>{selectedDriver && selectedDriver[trial]}</p>
                    </Col>
                  )
                }
              </Row>
          }
          <br/>
          {
            raceController.getRaceType() == "crono" ?
              <Row>
                <Col>
                  <p className="thin">Last Lap</p>
                  <b>{selectedDriver && (selectedDriver.getLastLap() == "" ? "--:--:---" : selectedDriver.getLastLap())}</b>
                </Col>
                <Col>
                  <p className="thin">Best Lap</p>
                  <b>{selectedDriver && (selectedDriver.getBestLap() == "" ? "--:--:---" : selectedDriver.getBestLap())}</b>
                </Col>
                <Col>
                  <p className="thin">Laps</p>
                  <b>{selectedDriver && (selectedDriver.getLaps() == "" ? "--:--:---" : selectedDriver.getLaps())}</b>     
                </Col>
              </Row> : 
              ""
          }
             
          <br/>
          <Row>
            {
              raceController.getRaceType() == "crono" ? 
                <>
                  <Col>
                    <p className="thin">Gap To First</p>
                    <b>{selectedDriver && (selectedDriver.getGapToFirst() == "" ? "--:--:---" : selectedDriver.getGapToFirst())}</b>
                  </Col>
                  <Col>
                    <p className="thin">Dif</p>
                    <b>{selectedDriver && (selectedDriver.getDif() == "" ? "--:--:---" : selectedDriver.getDif())}</b>
                  </Col>
                </> : 
                ""
            }
            
            {
              raceController.getRaceType() != "crono" ?
                <Col>
                  <p className="thin">Somma</p>
                  <b>{selectedDriver && selectedDriver.getSum()}</b>
                </Col> : 
                ""
            }
          </Row>
        </div>
      </SwipeableDrawer>
    </>
  );
}

// info section
function Infos(props) {
  let raceController = props.raceController

  return (
    <>
      <Row id="general-info">
        <Col md="6" id="event">
          <h1>{raceController.getEventName()}</h1>
          <h3 id="description">{raceController.getRaceName()}</h3>
          <p>{raceController.getTrackName()}</p>
        </Col>
        {raceController.getRaceType() == "crono" ?
        <>
        <Col className="text-center" md="6">
          <Row>
            <Col>
              <p>
                {raceController.getTimeToGo() == "-"
                  ? "Giro attuale"
                  : "Partenza"}
              </p>
              <h4>
                {raceController.getTimeToGo() == "-"
                  ? raceController.getLaps()
                  : raceController.getTimeToGo()}
              </h4>
            </Col>
            <Col>
              <p>Record</p>
              <h4>{raceController.getBestTime()}</h4>
            </Col>
            <Col>
              <p>Stato</p>
              <h4>{raceController.getFlag()}</h4>
            </Col>
          </Row>
        </Col>
        <div className="text-center">
          <button
            className="no-margin mb-3 mt-2"
            onClick={() =>
              window.open(
                "https://drive.google.com/drive/folders/1pFy8nX1oBBuQ7sOe0ysxut2kooV7Xj6F?usp=sharing",
                "_blank"
              )
            }>
            <h5>Risultati</h5>
            <h6 className="text-center">Schedule</h6>
          </button>
        </div>
        </> :
        ""}
      </Row>
    </>
  );
}

export default LiveTiming;
