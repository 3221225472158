import Database from "../components/database";
import Driver from "../models/driver";
import moment from "moment";

class DriverController extends Driver {

  constructor() {
    super()
    this.state = undefined
    this.overrideState = undefined
  }

  updateInfo() {}

  // GETTER
  // crono info getter
  getPosition() { return this.position }
  getName() { return this.name }
  getTeam() { return this.team }
  getT1() { return this.t1 }
  getT2() { return this.t2 }
  getT3() { return this.t3 }
  getLastLap() { return this.last_lap }
  getBestLap() { return this.best_lap }
  getLaps() { return this.laps }
  getGapToFirst() { return this.gap_to_first }
  getDif() { return this.dif }
  getRegNumber() { return this.regNumber }

  // point info getter 
  getDriverCode() { return this.driver_code } 
  getIdMask() { return this.id_mask }
  getSurname() { return this.surname }
  getSum() { return this.sum }
  // getName() { return this.name }   defined above
  // getT1() { return this.t1 }     
  // getT2() { return this.t2 }
  // getT3() { return this.t3 }

  // SETTER
  setPosition(position, auto_save = true) {
    // correct type check
    if(isNaN(position)) {
      console.warn("Position is not a number:", position, typeof position)
      position = -1
    }

    this.position = parseInt(position)
    if(auto_save) this.updateInfo()
  }

  setName(name, auto_save = true) {
    // correct type check
    if(typeof name != "string") {
      console.warn("Name is not a string:", name, typeof name)
      name = ""
    }

    this.name = name
    if(auto_save) this.updateInfo()
  }

  setTeam(team, auto_save = true) {
    // correct type check
    if(typeof team != "string") {
      console.warn("Team is not a string:", team, typeof team)
      team = ""
    }

    this.team = team
    if(auto_save) this.updateInfo()
  }

  setT1(t1, auto_save = true) {
    // correct type check
    if(typeof t1 != "string") {
      console.warn("t1 is not a string:", t1, typeof t1)
      t1 = ""
    }
    
    this.t1 = t1
    if(auto_save) this.updateInfo()
  }

  setT2(t2, auto_save = true) {
    // correct type check
    if(typeof t2 != "string") {
      console.warn("t2 is not a string:", t2, typeof t2)
      t2 = ""
    }

    this.t2 = t2
    if(auto_save) this.updateInfo()
  }

  setT3(t3, auto_save = true) {
    // correct type check
    if(typeof t3 != "string") {
      console.warn("t3 is not a string:", t3, typeof t3)
      t3 = ""
    }

    this.t3 = t3
    if(auto_save) this.updateInfo()
  }

  setLastLap(last_lap, auto_save = true) {
    // correct type check
    if(typeof last_lap != "string") {
      console.warn("Last_lap is not a string:", last_lap, typeof last_lap)
      last_lap = ""
    }

    this.last_lap = last_lap
    if(auto_save) this.updateInfo()
  }

  setBestLap(best_lap, auto_save = true) {
    // correct type check
    if(typeof best_lap != "string") {
      console.warn("best_lap is not a string:", best_lap, typeof best_lap)
      best_lap = ""
    }

    this.best_lap = best_lap
    if(auto_save) this.updateInfo()
  }

  setLaps(laps, auto_save = true) {
    // correct type check
    if(typeof laps != "string") {
      console.warn("laps is not a string:", laps, typeof laps)
      laps = ""
    }

    this.laps = laps
    if(auto_save) this.updateInfo()
  }

  setGapToFirst(gap_to_first, auto_save = true) {
    // correct type check
    if(typeof gap_to_first != "string") {
      console.warn("Gap_to_first is not a string:", gap_to_first, typeof gap_to_first)
      gap_to_first = ""
    }

    this.gap_to_first = gap_to_first
    if(auto_save) this.updateInfo()
  }

  setDif(dif, auto_save = true) {
    // correct type check
    if(typeof dif != "string") {
      console.warn("dif is not a string:", dif, typeof dif)
      dif = ""
    }

    this.dif = dif
    if(auto_save) this.updateInfo()
  }

  setRegNumber(regNumber, auto_save = true) {
    // correct type check
    if(typeof regNumber != "string") {
      console.warn("dif is not a string:", regNumber, typeof regNumber)
      regNumber = ""
    }

    this.regNumber = regNumber
    if(auto_save) this.updateInfo()
  }

  setDriverCode(driver_code, auto_save = true) {
    // correct type check
    if(typeof driver_code != "string") {
      console.warn("Driver_code is not a string:", driver_code, typeof driver_code)
      driver_code = ""
    }

    this.driver_code = driver_code
    if(auto_save) this.updateInfo()
  }

  setIdMask(id_mask, auto_save = true) {
    // correct type check
    if(typeof id_mask != "string") {
      console.warn("id_mask is not a string:", id_mask, typeof id_mask)
      id_mask = ""
    }

    this.id_mask = id_mask
    if(auto_save) this.updateInfo()
  }

  setSurname(surname, auto_save = true) {
    // correct type check
    if(typeof surname != "string") {
      console.warn("Surname is not a string:", surname, typeof surname)
      surname = ""
    }

    this.surname = surname
    if(auto_save) this.updateInfo()
  }

  setSum(sum, auto_save = true) {
    // correct type check
    if(!(typeof sum != "string" ^ isNaN(sum))) {
      console.warn("sum is not a string:", sum, typeof sum)
      sum = ""
    }

    this.sum = sum
    if(auto_save) this.updateInfo()
  }

  // check if time format is correct
  isTime(value) {
    return value.includes(':')
  }

  // time sum function
  makeSum(trials) {
    let firstTrial = this[trials[0]]
    if(!firstTrial) {
      this.setSum("")
    }
    else if(firstTrial && !firstTrial.includes(":")) {
      // in this case they are points
      // the point default value is "--"
      let sum = 0
      for(let trial of trials) {
        sum += parseInt(this[trial]) || 0
      }
      this.setSum(sum)
    }
    else {
      // in this case they are times
      let timeSum = moment("00:00:000", "mm:ss:SSS")
      
      for(let trial of trials) {
        let elementTime = moment(this[trial], "mm:ss:SSS")
        timeSum = timeSum.add(elementTime)
      }

      let minutes = ('0' + timeSum.minutes()).slice(-2)
      let seconds = ('0' + timeSum.seconds()).slice(-2)
      let milliseconds = ('00' + timeSum.milliseconds()).slice(-3)

      this.setSum(minutes + ':' + seconds + ':' + milliseconds)
    }
}
}

export default DriverController