class Race {
  constructor() {
    this.event_name = ""
    this.race_name = ""
    this.track_name = ""
    this.time_to_go = ""
    this.laps = ""
    this.best_time = ""
    this.flag = ""
    this.driver_list = []
    this.race_type = ""
  }
}

export default Race