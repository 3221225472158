import Race from "../models/race"
import Database from "../components/database"
import DriverController from "./driver_controller"
import moment from "moment"

class RaceController extends Race {
  constructor() {
    super()
    this.state = undefined
    this.overrideState = undefined
  }

  setState(state) {
    this.state = state
    this.updateInfo()
  }

  setOverrideState(overrideState) {
    this.overrideState = overrideState
    this.updateInfo()
  }

  updateInfo() {
    if(this.state != undefined) {
      let newIntstance = new RaceController()
      Object.assign(newIntstance, this)
      this.state(newIntstance)
    }
    else if(this.overrideState != undefined) this.overrideState()
  }

  // GETTER
  getEventName() { return this.event_name }
  getRaceName() { return this.race_name }
  getTrackName() { return this.track_name }
  getTimeToGo() { return this.time_to_go }
  getLaps() { return this.laps }
  getBestTime() { return this.best_time }
  getFlag() { return this.flag }
  getDriverList() { return this.driver_list }
  getRaceType() { return this.race_type}
  getTrials() { return this.trials || [] }

  // SETTER
  setEventName(event_name, auto_save = true) {
    // correct type check
    if(typeof event_name != "string") {
      console.warn("Event_name is not a string:", event_name, typeof event_name)
      event_name = "" 
    }

    this.event_name = event_name
    if(auto_save) this.updateInfo()
  }

  setRaceName(race_name, auto_save = true) {
    // correct type check
    if(typeof race_name != "string") {
      console.warn("Race_name is not a string:", race_name, typeof race_name)
      race_name = ""
    }

    this.race_name = race_name
    if(auto_save) this.updateInfo()
  }

  setTrackName(track_name, auto_save = true) {
    // correct type check
    if(typeof track_name != "string") {
      console.warn("Track_name is not a string:", track_name, typeof track_name)
      track_name = ""
    }

    this.track_name = track_name
    if(auto_save) this.updateInfo()
  }

  setTimeToGo(time_to_go, auto_save = true) {
    // correct type check
    if(typeof time_to_go != "string") {
      console.warn("time_to_go is not a string:", time_to_go, typeof time_to_go)
      time_to_go = ""
    }
    this.time_to_go = time_to_go
    if(auto_save) this.updateInfo()
  }

  setLaps(laps, auto_save = true) {
    // correct type check
    if(typeof laps != "string") {
      console.warn("Laps is not a string:", laps, typeof laps)
      laps = ""
    }
    
    this.laps = laps
    if(auto_save) this.updateInfo()
  }

  setBestLap(best_time, auto_save = true) {
    // correct type check
    if(typeof best_time != "string") {
      console.warn("Best_time is not a string:", best_time, typeof best_time)
      best_time = ""
    }

    this.best_time = best_time
    if(auto_save) this.updateInfo()
  }

  setFlag(flag, auto_save = true) {
    // correct type check
    if(typeof flag != "string") {
      console.warn("Flag is not a string:", flag, typeof flag)
      flag = ""
    }

    this.flag = flag
    if(auto_save) this.updateInfo()
  }

  setDriverList(driver_list, auto_save = false) {
    this.driver_list = driver_list
    if(auto_save) this.updateInfo()
  }

  setRaceType(race_type, auto_save = false) {
    this.race_type = race_type
    if(auto_save) this.updateInfo()
  }

  setTrials(trials, auto_save = false) {
    this.trials = trials
    if(auto_save) this.updateInfo()
  }

  // load race type from server => crono/point
  async loadRaceType() {
    let DB = new Database()
    let raceType = await DB.getRaceKind()
    this.setRaceType(raceType)
  }

  // loads event_info riceved from server
  loadEventInfo(event_info) {
    let auto_save = false

    this.setEventName(event_info['event_name'], auto_save)
    this.setRaceName(event_info['run_name'], auto_save)
    this.setTrackName(event_info['track_name'], auto_save)
    this.setTimeToGo(event_info['time_to_go'], auto_save)
    this.setLaps(event_info['laps'], auto_save)
    this.setBestLap(event_info['best_lap_time'], auto_save)
    this.setFlag(event_info['flag'], auto_save)
  }

  // loads driver_list riceved from server
  async loadDriverList(driver_list) {
    let auto_save = false
    let list = []
    
    // setting empty list
    this.setDriverList([], auto_save)

    for(let driver of driver_list) {
      let newDriver = new DriverController()
      if(this.getRaceType() == "crono") {
        // crono race
        newDriver.setName(driver['reg_number'] + " - " + driver['fullname'], auto_save)
        newDriver.setTeam(driver['team'], auto_save)
        newDriver.setPosition(driver['position'], auto_save)
        newDriver.setT1(driver['section0'], auto_save)
        newDriver.setT2(driver['section1'], auto_save)
        newDriver.setT3(driver['section2'], auto_save)
        newDriver.setLastLap(driver['last_time'], auto_save)
        newDriver.setBestLap(driver['best_time'], auto_save)
        newDriver.setLaps(driver['laps'], auto_save)
        newDriver.setGapToFirst(driver['gapto1'], auto_save)
        newDriver.setDif(driver['difference'], auto_save)
        newDriver.setRegNumber(driver['reg_number'])
      } else {
        // poiny race
        newDriver.setDriverCode(driver['driver_code'], auto_save)
        newDriver.setIdMask(driver['id_mask'], auto_save)
        newDriver.setName(driver['name'], auto_save)
        newDriver.setSurname(driver['surname'], auto_save)
        delete driver['driver_code']
        delete driver['id_mask']
        delete driver['name']
        delete driver['surname']
        
        for(let key of Object.keys(driver)) {
          newDriver[key] = driver[key]
        }

        newDriver.makeSum(Object.keys(this.getTrials()))
      }

      list.push(newDriver)
    }

    let trials_list = this.getTrials()

    if(this.getRaceType() == "crono") {
      // order by position
      list = list.sort((a,b) => a.getPosition() > b.getPosition() ? 1 : -1)
    } else {
      // order by sum
      list = list.sort(function(a, b) {
        let t_a = Object.keys(trials_list).map(trial => a[trial])
        let t_b = Object.keys(trials_list).map(trial => b[trial])

        // check if there is at least one trial
        if(!t_a[0])
          return 0

        if(!a.isTime(t_a[0])) {
          for(let i = 0; i < t_a.length; i++) {
            if((t_a[i] != '--') && (t_b[i] == '--')) return -1
            if((t_a[i] == '--') && (t_b[i] != '--')) return 1
          }
          if(a.getSum() > b.getSum()) return -1
          else if(a.getSum() < b.getSum()) return 1
          else return 0
        } else {
          for(let i = 0; i <  t_a.length; i++) {
            if((t_a[i] != '00:00:000') && (t_b[i] == '00:00:000')) return -1
            if((t_a[i] == '00:00:000') && (t_b[i] != '00:00:000')) return 1
          }

          // IT WASN'T WORKING ON SAFARI
          // if(new Date('0 00:' + a.getSum()) > new Date('0 00:' + b.getSum())) return 1
          // else if(new Date('0 00:' + a.getSum()) < new Date('0 00:' + b.getSum())) return -1
          // else return 0

          if(moment(a.getSum(), "mm:ss:SSS").isAfter(moment(b.getSum(), "mm:ss:SSS"))) return 1
          else if(moment(a.getSum(), "mm:ss:SSS").isBefore(moment(b.getSum(), "mm:ss:SSS"))) return -1
          else return 0
        }
      })


      list.map((driver, i) => driver.setPosition(i + 1))
    }

    this.setDriverList(list, auto_save)
  }

  // loads info from server
  async loadInfo() {
    let DB = new Database()
    await this.loadRaceType()
    
    if(this.getRaceType() == "crono") {

      // crono loading
      DB.getData().then((response) => {
        let data = response['data']
        let eventInfo = data['eventInfo'][0]
        let driverList = data['driverList']
        this.loadEventInfo(eventInfo)
        this.loadDriverList(driverList)
      })
    } else {
      // point race loading
      let DB = new Database()

      DB.getTrials((result) => {
        let trials = {}
        for(let item of result) {
          trials[item['ID']] = item['name']
        }
        this.setTrials(trials)
        DB.getDrivers().then((driverList) => {
          this.loadDriverList(driverList)
        })
      })
    }

    window.setTimeout(() => this.loadInfo(), 3000)
    this.updateInfo()
  }
}

export default RaceController