import React, {useState} from "react";
import { Row, Col } from "react-bootstrap";
import FlipMove from "react-flip-move";
import RaceController from "../../controllers/race_controller";


function Table(props) {
  let raceController = props.raceController
  let setSelectedDriver = props.setSelectedDriver
  const raceKind = props.raceKind

  return (
    <div id="table-container" className="mb-4">
      <table id="livetiming">
        {
          raceKind == "crono" ? 
          <CronoTable setSelectedDriver={setSelectedDriver} raceController={raceController}/> :
          <MixedTable setSelectedDriver={setSelectedDriver} raceController={raceController}/>
        }
      </table>
    </div>
  );
}

function CronoTable(props) {
  let raceController = props.raceController
  let setSelectedDriver = props.setSelectedDriver

  const cronoHeaders = [
    "Pilota",
    "T1",
    "T2",
    "T3",
    "Last lap",
    "Best lap",
    "Laps",
    "Gap to 1°",
    "DIF",
  ];
  
  return (
    <>
      <thead>
        <tr>
          {cronoHeaders.map((value, index) => (
            <td key={index} className="red-cell">
              {value}
            </td>
          ))}
        </tr>
      </thead>
      <FlipMove
      typeName="tbody">
        {
          //prints row for each pilotscreen
          raceController.getDriverList().map((driver, index) => {
            return (
              <tr key={driver.getName() + driver.getSurname()} onClick={() => setSelectedDriver(driver)}>
                <td className="pilot-info white-cell">
                  <Row className="text-left">
                    <Col xs="3" className="position-container">
                      <h6 className="position">
                        {driver.getPosition()}
                      </h6>
                    </Col>
                    <Col xs="8" className="p-0">
                      <h6 className="mb-0 overflow-hidden">
                        {driver.getName()}
                      </h6>
                      <p>{driver.getTeam()}</p>
                    </Col>
                  </Row>
                </td>
                <td className="white-cell">
                  <h6 className="m-0">
                    {driver.getT1() == "" ? "--:--:--" : driver.getT1()}
                  </h6>
                </td>
                <td className="white-cell">
                  <h6 className="m-0">
                    {driver.getT2() == "" ? "--:--:--" : driver.getT2()}
                  </h6>
                </td>
                <td className="white-cell">
                  <h6 className="m-0">
                    {driver.getT3() == "" ? "--:--:--" : driver.getT3()}
                  </h6>
                </td>
                <td className="white-cell">
                  <h6 className={"m-0"}>
                    {driver.getLastLap() == "" ? "--:--:--" : driver.getLastLap()}
                  </h6>
                </td>
                <td className="white-cell">
                  <h6 className={driver.getBestLap() == raceController.getBestTime() ? "m-0 red" : "m-0"}>
                    {driver.getBestLap() == "" ? "--:--:--" : driver.getBestLap()}
                  </h6>
                </td>
                <td className="white-cell">
                  <h6 className={"m-0"}>
                    {driver.getLaps() == "" ? "--:--:--" : driver.getLaps()}
                  </h6>
                </td>
                <td className="white-cell">
                  <h6 className={"m-0"}>
                    {driver.getGapToFirst() == "" ? "--:--:--" : driver.getGapToFirst()}
                  </h6>
                </td>
                <td className="white-cell">
                  <h6 className={"m-0"}>
                    {driver.getDif() == "" ? "--:--:--" : driver.getDif()}
                  </h6>
                </td>
              </tr>
            );
          })
        }
      </FlipMove>

    </>
  )
}

function MixedTable(props) {
  let raceController = props.raceController
  let setSelectedDriver = props.setSelectedDriver

  const mixedHeaders = [
    "Posizione",
    "Nome",
    "Cognome",
    ...Object.values(raceController.getTrials()),
    "Somma",
  ];

  return (
    <>
      <thead>
        <tr>
          {mixedHeaders.map((value, index) => (
            <td key={index} className="red-cell">
              <h5 className="m-2">{value}</h5>
            </td>
          ))}
        </tr>
      </thead>
      <FlipMove
      typeName="tbody">
        {
          //prints row for each pilotscreen
          raceController.getDriverList().map((driver, index) => {
            return (
              <tr key={driver.getDriverCode()} onClick={() => setSelectedDriver(driver)}>
                <td className="white-cell"><h5 className="m-2">{index + 1}</h5></td>
                <td className="white-cell"><h5 className="m-2">{driver.getName()}</h5></td>
                <td className="white-cell"><h5 className="m-2">{driver.getSurname()}</h5></td>
                {
                  Object.keys(raceController.getTrials()).map((trial) => 
                    <td className="white-cell"><h5 className="m-2">{driver[trial]}</h5></td>
                  )
                }
                <td className="white-cell"><h5 className="m-2">{driver.getSum()}</h5></td>
              </tr>
            );
          })
        }
      </FlipMove>
    </>
  )
}

export default Table