import React from 'react'

function Maintenance() {
  return (
    <div style={{backgroundColor: "#1e2945",height: '100vh', display: "grid", justifyContent: "center", alignItems: "center"}}>
      <img src="https://cdn.dribbble.com/users/397346/screenshots/1442485/media/c4b42bd55f5efe2deabca5809fb9479c.gif"/>
    </div>
  )
}

export default Maintenance